import {
  createContext,
  memo,
  type MutableRefObject,
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMedia } from 'react-use';
import { useTheme } from '@emotion/react';
import { noop } from 'lodash';

import { useBoolState, useMemoizedBundle } from '@eversity/ui/utils';

import { DEFAULT_AUDIO_VOLUME } from '../constants';
import { type Track, type Tracklist } from '../types';
import { findTrackIndex, findTracklistIndex } from './utils';

export type AudioPlayerContextValue = {
  isAudioPlaying: boolean;
  isDrawerOpen: boolean;
  isMobileView: boolean;
  isPortrait: boolean;
  isMuted: boolean;
  isFileLoading: boolean;
  audioRef: MutableRefObject<HTMLAudioElement | null>;
  audioVolume: number;
  audioDuration: number;
  audioCurrentTime: number;
  onChangeAudioVolume: (newVolume: number) => void;
  onClickMute: () => void;
  onToggleAudioPlay: () => void;
  onOpenDrawer: () => void;
  onCloseDrawer: () => void;
  onClickPlayer: () => void;
  onPauseAudio: () => void;

  onNextTrack: () => void;
  onPreviousTrack: () => void;
  onNextTracklist: () => void;
  onPreviousTracklist: () => void;

  currentTracklist: Tracklist | null;
  currentTrack: Track | null;
  shouldAutoplay: boolean;
};

export const AudioPlayerContext = createContext<AudioPlayerContextValue>({
  isAudioPlaying: false,
  isDrawerOpen: false,
  isMobileView: false,
  isPortrait: false,
  isMuted: false,
  isFileLoading: false,
  audioRef: null,
  audioVolume: DEFAULT_AUDIO_VOLUME,
  audioDuration: -1,
  audioCurrentTime: -1,

  onChangeAudioVolume: noop,
  onClickMute: noop,
  onToggleAudioPlay: noop,
  onOpenDrawer: noop,
  onCloseDrawer: noop,
  onClickPlayer: noop,
  onPauseAudio: noop,

  onNextTrack: noop,
  onPreviousTrack: noop,
  onNextTracklist: noop,
  onPreviousTracklist: noop,

  currentTracklist: null,
  currentTrack: null,
  shouldAutoplay: false,
});

export type AudioPlayerContextProviderProps = {
  /** Callback called when audio ends. */
  onAudioEnd?: () => void;
  /** Callback called when the playing track changes. */
  onChangeTrack?: (tracklistId: string, trackId: string) => void;
  /** List of tracklists. */
  tracklists: Tracklist[];
  /** Id of the initial tracklist. */
  initialTracklistId: string;
  /** Id of the initial track. */
  initialTrackId: string;
  /** Children to render. */
  children?: ReactNode;
};

export const AudioPlayerContextProviderBase = ({
  onAudioEnd = noop,
  onChangeTrack = noop,
  tracklists,
  initialTracklistId,
  initialTrackId,
  children = null,
}: AudioPlayerContextProviderProps) => {
  const [shouldAutoplay, setShouldAutoplay] = useState(false);

  const [currentRessourcesIndexes, setCurrentRessourcesIndexes] = useState({
    tracklist: findTracklistIndex(tracklists, initialTracklistId),
    track: findTrackIndex(tracklists, initialTracklistId, initialTrackId),
  });

  const isTracklistEmpty =
    !tracklists[currentRessourcesIndexes.tracklist]?.tracks.length;
  const currentTracklist = !isTracklistEmpty
    ? tracklists[currentRessourcesIndexes.tracklist]
    : null;
  const currentTrack = !isTracklistEmpty
    ? (tracklists[currentRessourcesIndexes.tracklist].tracks[
        currentRessourcesIndexes.track
      ] ?? null)
    : null;

  useEffect(() => {
    setCurrentRessourcesIndexes({
      tracklist: findTracklistIndex(tracklists, initialTracklistId),
      track: findTrackIndex(tracklists, initialTracklistId, initialTrackId),
    });
  }, [tracklists, initialTracklistId, initialTrackId]);

  // When the current track changes, we call the onChangeTrack callback.
  useEffect(() => {
    if (
      tracklists[currentRessourcesIndexes.tracklist]?.id &&
      tracklists[currentRessourcesIndexes.tracklist].tracks[
        currentRessourcesIndexes.track
      ]?.id
    ) {
      onChangeTrack(
        tracklists[currentRessourcesIndexes.tracklist].id,
        tracklists[currentRessourcesIndexes.tracklist].tracks[
          currentRessourcesIndexes.track
        ].id,
      );
    }
    // There is infinite loop if we add onChangeTrack to the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRessourcesIndexes, tracklists]);

  const isFirstTrack = currentRessourcesIndexes.track === 0;
  const isLastTrack =
    !isTracklistEmpty &&
    currentRessourcesIndexes.track ===
      tracklists[currentRessourcesIndexes.tracklist].tracks.length - 1;
  const isFirstTracklist = currentRessourcesIndexes.tracklist === 0;
  const isLastTracklist =
    !isTracklistEmpty &&
    currentRessourcesIndexes.tracklist === tracklists.length - 1;

  const onNextTrack = useCallback(() => {
    setShouldAutoplay(true);

    if (isLastTrack && !isLastTracklist) {
      setCurrentRessourcesIndexes((prevIndex) => ({
        tracklist: prevIndex.tracklist + 1,
        track: 0,
      }));
    } else {
      setCurrentRessourcesIndexes((prevIndex) => ({
        ...prevIndex,
        track: prevIndex.track + 1,
      }));
    }
  }, [isLastTrack, isLastTracklist]);

  const onPreviousTrack = useCallback(() => {
    setShouldAutoplay(true);

    if (isFirstTrack && !isFirstTracklist) {
      setCurrentRessourcesIndexes((prevIndex) => ({
        tracklist: prevIndex.tracklist - 1,
        track: tracklists[prevIndex.tracklist - 1].tracks.length - 1,
      }));
    } else {
      setCurrentRessourcesIndexes((prevIndex) => ({
        ...prevIndex,
        track: prevIndex.track - 1,
      }));
    }
  }, [tracklists, isFirstTrack, isFirstTracklist, setCurrentRessourcesIndexes]);

  const onNextTracklist = useCallback(() => {
    setShouldAutoplay(true);

    if (!isLastTracklist) {
      setCurrentRessourcesIndexes((prevIndex) => ({
        tracklist: prevIndex.tracklist + 1,
        track: 0,
      }));
    }
  }, [isLastTracklist]);

  const onPreviousTracklist = useCallback(() => {
    setShouldAutoplay(true);

    if (!isFirstTracklist) {
      setCurrentRessourcesIndexes((prevIndex) => ({
        tracklist: prevIndex.tracklist - 1,
        track: 0,
      }));
    }
  }, [isFirstTracklist]);

  const [isDrawerOpen, onOpenDrawer, onCloseDrawer] = useBoolState(false);
  const [isPortrait, setIsPortrait] = useState(true);
  const [isFileLoading, setIsFileLoading] = useState(false);

  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const [isMuted, onMute, onUnmute] = useBoolState(false);
  const [audioVolume, setAudioVolume] = useState(DEFAULT_AUDIO_VOLUME);

  const [audioDuration, setAudioDuration] = useState(-1);
  const [audioCurrentTime, setAudioCurrentTime] = useState(-1);

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const theme = useTheme();

  const isMobileView = useMedia(`(max-width: ${theme.breakpoints.small})`);

  // TODO: metadata hack (https://developer.chrome.com/blog/media-session?hl=fr)

  const onPlayAudio = useCallback(async () => {
    if (!audioRef?.current) return;

    setIsFileLoading(true);
    await audioRef.current.play();
    setIsFileLoading(false);
    setIsAudioPlaying(true);
  }, [audioRef]);

  const onPauseAudio = useCallback(() => {
    if (!audioRef?.current) return;

    audioRef.current.pause();
    setIsAudioPlaying(false);
  }, [audioRef]);

  const onToggleAudioPlay = useCallback(async () => {
    if (!audioRef?.current) return;

    if (audioRef.current.paused) {
      await onPlayAudio();
    } else {
      onPauseAudio();
    }
  }, [audioRef, onPlayAudio, onPauseAudio]);

  useEffect(() => {
    // We manage volume in a separate state so we can still change it while the src is loading
    const audio = audioRef.current;

    if (audio) {
      audio.volume = audioVolume;
    }
  }, [audioVolume, audioRef]);

  useEffect(() => {
    const audio = audioRef.current;

    if (!audio) return noop;

    const onLoadedMetadata = () => {
      setAudioDuration(audio.duration);

      if (!audio.currentTime) {
        setAudioCurrentTime(0);
      }

      if (shouldAutoplay) {
        onToggleAudioPlay();
      }
    };

    const onAudioEndProxy = () => {
      setIsAudioPlaying(false);
      onNextTrack();
    };

    const onTimeUpdate = () => {
      setAudioCurrentTime(audio.currentTime);
    };

    const onOrientationChange = () => {
      setIsPortrait(window.screen.orientation.type.includes('portrait'));
    };

    audio.addEventListener('ended', onAudioEndProxy);
    audio.addEventListener('loadedmetadata', onLoadedMetadata);
    audio.addEventListener('timeupdate', onTimeUpdate);
    window.addEventListener('orientationchange', onOrientationChange);

    return () => {
      audio.removeEventListener('ended', onAudioEndProxy);
      audio.removeEventListener('loadedmetadata', onLoadedMetadata);
      audio.removeEventListener('timeupdate', onTimeUpdate);
      window.removeEventListener('orientationchange', onOrientationChange);
    };
  }, [
    currentTrack,
    onAudioEnd,
    onToggleAudioPlay,
    shouldAutoplay,
    onNextTrack,
  ]);

  const onClickPlayer = useCallback(() => {
    if (isMobileView) {
      onOpenDrawer();
    }
  }, [isMobileView, onOpenDrawer]);

  const onClickMute = useCallback(() => {
    if (isMuted) {
      onUnmute();
      if (!audioRef.current) return;
      audioRef.current.muted = false;
    } else {
      onMute();
      if (!audioRef.current) return;
      audioRef.current.muted = true;
    }
  }, [isMuted, onMute, onUnmute]);

  const onChangeAudioVolume = useCallback(
    (newVolume: number) => {
      setAudioVolume(newVolume);
    },
    [setAudioVolume],
  );

  const value = useMemoizedBundle<AudioPlayerContextValue>({
    isDrawerOpen,
    isAudioPlaying,
    isMobileView,
    isPortrait,
    isMuted,
    isFileLoading,

    audioRef,
    audioDuration,
    audioVolume,
    audioCurrentTime,

    onChangeAudioVolume,
    onOpenDrawer,
    onClickMute,
    onCloseDrawer,
    onToggleAudioPlay,
    onClickPlayer,
    onPauseAudio,

    onNextTrack,
    onPreviousTrack,
    onNextTracklist,
    onPreviousTracklist,

    currentTracklist,
    currentTrack,
    shouldAutoplay,
  });

  return (
    <AudioPlayerContext.Provider value={value}>
      {children}
    </AudioPlayerContext.Provider>
  );
};

export const AudioPlayerContextProvider = memo(AudioPlayerContextProviderBase);

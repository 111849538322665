import { uniqBy } from 'lodash';

import {
  NB_DAYS_BEFORE_INACTIVITY,
  STREAK_DAY_TYPES,
} from '@eversity/domain/constants';
import dayjs from '@eversity/services/dayjs';
import { type StudentAcademicTranscript } from '@eversity/types/domain';

export const getNumberOfDaysSinceAccessStarted = (
  accessStartDate: string | Date,
  date?: string | Date | number,
) =>
  dayjs(accessStartDate).isValid()
    ? dayjs(date).diff(dayjs(accessStartDate).startOf('day'), 'day')
    : null;

export const makeChartDataRelative = (
  kpis: { daysSinceRegistration: number; value: number }[],
) =>
  uniqBy(kpis, (kpi) => kpi.daysSinceRegistration)
    .sort((a, b) => b.daysSinceRegistration - a.daysSinceRegistration)
    .map(({ daysSinceRegistration, value }) => ({
      x: daysSinceRegistration,
      y: Math.round(value * 100) / 100,
    }));

export const makeChartDataWithDate = (
  kpis: { daysSinceRegistration: number; value: number }[],
  minDate: string,
) =>
  makeChartDataRelative(kpis).map(({ x, y }) => ({
    x: dayjs(minDate).add(x, 'days').toDate().getTime(),
    y,
  }));

export const getTranscriptTeachingUnitGradedAssignmentsCount = (
  teachingUnit: StudentAcademicTranscript['course']['teachingUnits'][0],
) => {
  const assignments = [
    ...teachingUnit.assignments,
    ...(teachingUnit.lessons || []).flatMap(
      ({ assignments: lessonAssignments }) => lessonAssignments,
    ),
  ];

  return {
    count: assignments.filter(({ submission }) => submission?.correctionDate)
      .length,
    total: assignments.length,
  };
};

export const getTranscriptGradedAssignmentsCount = (
  transcript?: StudentAcademicTranscript,
) =>
  (transcript?.course.teachingUnits || []).reduce<{
    count: number;
    total: number;
  }>(
    (acc, teachingUnit) => {
      const { count, total } =
        getTranscriptTeachingUnitGradedAssignmentsCount(teachingUnit);

      return {
        count: acc.count + count,
        total: acc.total + total,
      };
    },
    { count: 0, total: 0 },
  );

/**
 * this method checks that a student logged in less than 28 days ago. It takes the last login date as a parameter
 * @param lastActiveTimestamp
 * @returns {boolean}
 */
export const isStudentActive = (lastActiveTimestamp: string): boolean => {
  const inputDate = dayjs(lastActiveTimestamp);
  const twentyEightDaysAgo = dayjs().subtract(NB_DAYS_BEFORE_INACTIVITY, 'day');

  return (
    inputDate.isSameOrBefore(dayjs()) && inputDate.isAfter(twentyEightDaysAgo)
  );
};

export const getStreaksFromConnectionDays = (
  currentDay: dayjs.Dayjs,
  connectionDays: string[],
) => {
  const daysTypes: Record<string, STREAK_DAY_TYPES> = {};
  const startOfWeek = dayjs(currentDay).startOf('week').weekday(0);

  for (
    let date = currentDay.endOf('week');
    date >= startOfWeek;
    date = date.subtract(1, 'day')
  ) {
    const nextDay = date.add(1, 'day').toISOString();
    const stringifiedDate = date.toISOString();
    const hasAnEntryForCurrentDay = connectionDays.find((cd) =>
      dayjs(cd).isSame(date, 'day'),
    );

    if (hasAnEntryForCurrentDay) {
      if (
        daysTypes[nextDay] === STREAK_DAY_TYPES.CURRENT_DAY ||
        daysTypes[nextDay] === STREAK_DAY_TYPES.PAST_DAY_STREAK ||
        daysTypes[nextDay] === STREAK_DAY_TYPES.WEEK_END
      ) {
        daysTypes[stringifiedDate] = STREAK_DAY_TYPES.PAST_DAY_STREAK;
      } else {
        daysTypes[stringifiedDate] = STREAK_DAY_TYPES.PAST_DAY_STREAK_KILLED;
      }
    }

    if (date.isSame(currentDay, 'day')) {
      daysTypes[stringifiedDate] = STREAK_DAY_TYPES.CURRENT_DAY;
    }

    if (date.isBefore(currentDay, 'day') && !hasAnEntryForCurrentDay) {
      daysTypes[stringifiedDate] = STREAK_DAY_TYPES.PAST_DAY_NO_STREAK;
    }

    if (date.isAfter(currentDay, 'day')) {
      daysTypes[stringifiedDate] = STREAK_DAY_TYPES.FUTURE_DAY;
    }

    if (date.day() === 6 || date.day() === 0) {
      daysTypes[stringifiedDate] = STREAK_DAY_TYPES.WEEK_END;
    }
  }

  return daysTypes;
};

import { useInfiniteQuery, useQueries, useQuery } from '@tanstack/react-query';

import {
  type ASSIGNMENT_QUESTION_VIEWS,
  USER_VIEWS,
} from '@eversity/domain/constants';
import { UsersRepository } from '@eversity/domain/data/repositories';
import {
  type GetCurrentUserExamRegistrationLinkQuery,
  type GetStudentActivitySummaryQuery,
  type GetStudentVirtualClassroomsQuery,
  type StudentViewFull,
  type StudentViewPersonal,
  type WelcomePackStatus,
} from '@eversity/types/domain';
import {
  type UseInfiniteQueryParams,
  type UseQueryParams,
} from '@eversity/types/web';

import http from '../../../services/http/api';

const usersRepository = new UsersRepository(http);

export const useUser = (useQueryParams?: UseQueryParams<StudentViewFull>) =>
  useQuery({
    queryKey: ['user'],
    queryFn: () => usersRepository.getCurrentUser(),
    ...useQueryParams,
  });

export const useUserPersonalDetails = (
  useQueryParams?: UseQueryParams<StudentViewPersonal>,
) =>
  useQuery({
    queryKey: ['user-personal'],
    queryFn: () =>
      usersRepository.getCurrentUser({ view: USER_VIEWS.PERSONAL }),
    ...useQueryParams,
  });

export const useUserCalendarEvents = (
  query?: Parameters<UsersRepository['getUserCalendarEvents']>[0],
  useQueryParams?: UseQueryParams<
    Awaited<ReturnType<UsersRepository['getUserCalendarEvents']>>
  >,
) =>
  useQuery({
    queryKey: ['calendar', query || {}],
    queryFn: () => usersRepository.getUserCalendarEvents(query || {}),
    ...useQueryParams,
  });

export const useUserMails = (
  labelId: string,
  useQueryParams?: UseQueryParams<
    Awaited<ReturnType<UsersRepository['getUserMails']>>
  >,
) =>
  useQuery({
    queryKey: ['userMails', labelId],
    queryFn: () => usersRepository.getUserMails(labelId),
    ...useQueryParams,
  });

export const useUserAssignments = (
  courseId: string,
  classId: string,
  query?: Parameters<UsersRepository['getUserAssignments']>[2],
  useQueryParams?: UseQueryParams<
    Awaited<ReturnType<UsersRepository['getUserAssignments']>>
  >,
) =>
  useQuery({
    queryKey: ['user-assignments', courseId, classId, query],
    queryFn: () => usersRepository.getUserAssignments(courseId, classId, query),
    enabled: !!(courseId && classId),
    ...useQueryParams,
  });

export const useUserAssignment = (
  assignmentId: string,
  courseId: string,
  classId: string,
  teachingUnitId: string,
  lessonId?: string | null,
  useQueryParams?: UseQueryParams<
    Awaited<ReturnType<UsersRepository['getUserAssignment']>>
  >,
) =>
  useQuery({
    queryKey: [
      'user-assignment',
      assignmentId,
      courseId,
      classId,
      teachingUnitId,
      lessonId,
    ],
    queryFn: () =>
      usersRepository.getUserAssignment(
        assignmentId,
        courseId,
        classId,
        teachingUnitId,
        lessonId,
      ),
    enabled: !!(courseId && classId && teachingUnitId),
    ...useQueryParams,
  });

export const useUserAssignmentsSummary = (courseId: string, classId: string) =>
  useQuery({
    queryKey: ['assignments-summary', courseId, classId],
    queryFn: () => usersRepository.getUserAssignmentsSummary(courseId, classId),
    enabled: !!(courseId && classId),
  });

export const useUserAssignmentQuestions = (
  assignmentId: string,
  courseId: string,
  classId: string,
  teachingUnitId: string,
  lessonId: string | null,
  view: ASSIGNMENT_QUESTION_VIEWS,
  useQueryParams?: UseQueryParams<
    Awaited<ReturnType<UsersRepository['getAssignmentQuestions']>>
  >,
) =>
  useQuery({
    queryKey: ['assignment-questions', assignmentId, view],
    queryFn: () =>
      usersRepository.getAssignmentQuestions(
        assignmentId,
        courseId,
        classId,
        teachingUnitId,
        lessonId,
        view,
      ),
    enabled: !!(assignmentId && view),
    ...useQueryParams,
  });

export const useUserWelcomePackStatus = (
  courseId: string,
  classId: string,
  useQueryParams?: UseQueryParams<
    Awaited<ReturnType<UsersRepository['getWelcomePackStatus']>>
  >,
) =>
  useQuery({
    queryKey: ['welcome-pack', courseId, classId],
    queryFn: () => usersRepository.getWelcomePackStatus(courseId, classId),
    enabled: !!(courseId && classId),
    ...useQueryParams,
  });

export const useUserWelcomePackStatuses = (
  courseClassIds: { courseId: string; classId: string }[],
  useQueryParams?: UseQueryParams<{
    courseId: string;
    classId: string;
    welcomePackStatus: WelcomePackStatus;
  }>,
) =>
  useQueries({
    queries: courseClassIds.map(({ courseId, classId }) => ({
      queryKey: ['welcome-pack', courseId, classId],
      queryFn: () => usersRepository.getWelcomePackStatus(courseId, classId),
      select: (data: WelcomePackStatus) => ({
        courseId,
        classId,
        welcomePackStatus: data,
      }),
      ...useQueryParams,
    })),
  });

export const useUserVirtualClassrooms = (
  query: GetStudentVirtualClassroomsQuery,
  useQueryParams?: UseQueryParams<
    Awaited<ReturnType<UsersRepository['getVirtualClassrooms']>>
  >,
) =>
  useQuery({
    queryKey: ['virtual-classrooms', query],
    queryFn: () => usersRepository.getVirtualClassrooms(query),
    ...useQueryParams,
  });

export const useInfiniteUserVirtualClassrooms = (
  query: Omit<GetStudentVirtualClassroomsQuery, 'offset'>,
  useQueryParams?: UseInfiniteQueryParams<
    Awaited<ReturnType<UsersRepository['getVirtualClassrooms']>>
  >,
) =>
  useInfiniteQuery({
    queryKey: ['virtual-classrooms-infinite', query],
    queryFn: ({ pageParam }) =>
      usersRepository.getVirtualClassrooms({
        ...query,
        offset: ((pageParam as number) - 1) * query.limit,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.virtualClassrooms.length < query.limit
        ? undefined
        : allPages.length + 1,
    ...useQueryParams,
  });

export const useExamRegistrationLink = (
  query: GetCurrentUserExamRegistrationLinkQuery,
  useQueryParams?: UseQueryParams<
    Awaited<ReturnType<UsersRepository['getExamRegistrationLink']>>
  >,
) =>
  useQuery({
    queryKey: ['exam-registration-link', query],
    queryFn: () =>
      usersRepository.getExamRegistrationLink(query.courseId, query.classId),
    ...useQueryParams,
  });

export const useStudentCardLink = (
  courseId: string,
  classId: string,
  useQueryParams?: UseQueryParams<
    Awaited<ReturnType<UsersRepository['getStudentCardLink']>>
  >,
) =>
  useQuery({
    queryKey: ['student-card-link', { courseId, classId }],
    queryFn: () => usersRepository.getStudentCardLink(courseId, classId),
    throwOnError: false,
    ...useQueryParams,
  });

export const useStudentFeedbackCampaigns = (
  courseId: string,
  classId: string,
  useQueryParams?: UseQueryParams<
    Awaited<ReturnType<UsersRepository['getStudentFeedbackCampaigns']>>
  >,
) =>
  useQuery({
    queryKey: ['student-feedback-campaigns'],
    queryFn: () =>
      usersRepository.getStudentFeedbackCampaigns(courseId, classId),
    throwOnError: false,
    enabled: !!(courseId && classId),
    ...useQueryParams,
  });

export const useStudentActivitySummary = (
  query: GetStudentActivitySummaryQuery,
  useQueryParams?: UseQueryParams<
    Awaited<ReturnType<UsersRepository['getStudentActivitySummary']>>
  >,
) =>
  useQuery({
    queryKey: ['user-current-streak'],
    queryFn: () => usersRepository.getStudentActivitySummary(query),
    ...useQueryParams,
  });

export enum USER_ROLES {
  ADMIN = 'ADMIN',
  ADMINISTRATIVE = 'ADMINISTRATIVE',
  ANIMATION = 'ANIMATION',
  CAMPUS_TESTER = 'CAMPUS_TESTER',
  COACH = 'COACH',
  CONCEPTION = 'CONCEPTION',
  CORRECTOR = 'CORRECTOR',
  EDITOR = 'EDITOR',
  INTERNSHIPS = 'INTERNSHIPS',
  SALES = 'SALES',
  SUPER_ADMIN = 'SUPER_ADMIN',
  SUPPORT = 'SUPPORT',
  TEACHER = 'TEACHER',
}

export enum USER_VIEWS {
  MIN = 'MIN',
  FULL = 'FULL',
  EXTERNAL = 'EXTERNAL',
  PERSONAL = 'PERSONAL',
}

export enum USER_COURSE_ACCESS_TYPES {
  FULL = 'FULL',
  LIMITED = 'LIMITED',
  PENDING = 'PENDING',
}

// Make sure to keep these sanitized, they are used in email addresses.
export const ACCOUNT_EMAIL_DOMAIN = 'enaco.fr';
export const STUDENT_ACCOUNT_EMAIL_SUFFIX = '-students';
export const INTERNAL_ACCOUNT_EMAIL_SUFFIX = '';

// Maximum number of profile pictures to keep.
export const USER_PICTURES_HISTORY_LENGTH = 4;

export const USER_CALENDAR_EVENTS_DEFAULT_LIMIT = 100;

export const USER_APPOINTMENTS_DEFAULT_LIMIT = 100;

export enum PING_STATES {
  // Start / continue.
  ACTIVE = 'ACTIVE',
  // Stop.
  INACTIVE = 'INACTIVE',
}

// Maximum time between 2 consecutive pings to consider that this is still the same session.
// In seconds.
export const MAX_ACTIVITY_PING_DELAY = 5 * 60;

export enum USER_COURSE_KPIS_TYPES {
  PROGRESS = 'PROGRESS',
  TIME_SPENT = 'TIME_SPENT',
  OVERALL_AVERAGE = 'OVERALL_AVERAGE',
}

export enum STUDENT_PLANS {
  INITIAL = 'INITIAL',
  MPE = 'MPE',
  TP_HDF = 'TP_HDF',
  APPRENTISSAGE = 'APPRENTISSAGE',
  APPRENTI_SANS_ENTREPRISE = 'APPRENTI_SANS_ENTREPRISE',
  CONTRAT_PROFESSIONNALISATION = 'CONTRAT_PROFESSIONNALISATION',
  PERIODE_PROFESSIONNALISATION = 'PERIODE_PROFESSIONNALISATION',
  CPF = 'CPF',
  PLAN_FORMATION = 'PLAN_FORMATION',
  PLAN_SAUVEGARDE_EMPLOI = 'PLAN_SAUVEGARDE_EMPLOI',
  CONVENTION_RECLASSEMENT_PERSONNALISE = 'CONVENTION_RECLASSEMENT_PERSONNALISE',
  CIF = 'CIF',
  CONTRAT_SECURISATION_PROFESSIONNEL = 'CONTRAT_SECURISATION_PROFESSIONNEL',
  CONGE_FORMATION_PROFESSIONNEL = 'CONGE_FORMATION_PROFESSIONNEL',
  PLAN_LICENCIEMENT = 'PLAN_LICENCIEMENT',
  CONTRAT_TRANSITION_PROFESSIONNELLE = 'CONTRAT_TRANSITION_PROFESSIONNELLE',
  CONGE_RECLASSEMENT = 'CONGE_RECLASSEMENT',
  AIF = 'AIF',
  MISSION_LOCALE = 'MISSION_LOCALE',
  REGION = 'REGION',
  FINANCEMENT_ENTREPRISE = 'FINANCEMENT_ENTREPRISE',
  PLAN_DEPART_VOLONTAIRE = 'PLAN_DEPART_VOLONTAIRE',
  BOOST = 'BOOST',
}

export const STUDENTS_PLANS_NOT_ELIGIBLE_TO_EXAM_REGISTRATION = [
  STUDENT_PLANS.APPRENTI_SANS_ENTREPRISE,
  STUDENT_PLANS.BOOST,
];

export enum STUDENT_CARD_TYPES {
  ENACO = 'enaco',
  METIER = 'metier',
  NONE = 'none',
}

export const AVAILABLE_STUDENT_CARD_TYPES = [
  STUDENT_CARD_TYPES.ENACO,
  STUDENT_CARD_TYPES.METIER,
];

export enum MEDAL_TYPES {
  CONNECTION_STREAK = 'CONNECTION_STREAK',
}

export enum MEDAL_TIERS {
  NEUTRAL = 'NEUTRAL',
  BRONZE = 'BRONZE',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
}

export const MEDAL_TIERS_THRESHOLDS = {
  [MEDAL_TYPES.CONNECTION_STREAK]: {
    [MEDAL_TIERS.NEUTRAL]: 5,
    [MEDAL_TIERS.BRONZE]: 15,
    [MEDAL_TIERS.SILVER]: 30,
    [MEDAL_TIERS.GOLD]: 100,
  },
};

export enum STREAK_DAY_TYPES {
  WEEK_END = 'WEEK_END',
  CURRENT_DAY = 'CURRENT_DAY',
  PAST_DAY_STREAK = 'PAST_DAY_LOGGED',
  PAST_DAY_NO_STREAK = 'PAST_DAY_NO_STREAK',
  PAST_DAY_STREAK_KILLED = 'PAST_DAY_STREAK_KILLED',
  FUTURE_DAY = 'FUTURE_DAY',
}

export const NB_DAYS_BEFORE_INACTIVITY = 28;

export const GOOGLE_CALENDAR_URL = 'https://calendar.google.com/calendar/u/';

export const GOOGLE_MAIL_URL = 'https://mail.google.com/mail/u/';

export enum USER_LESSON_PROGRESS_SEQUENCE_POSITION_TYPES {
  READ = 'READ',
  LISTEN = 'LISTEN',
}
